export const MY_PAGE_TAB = [
  {
    id: 'bidProducts',
    name: '입찰 상품',
  },
  {
    id: 'soldProducts',
    name: '판매 상품',
  },
];
