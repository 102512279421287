export const PRODUCT_CATEGORY = [
  '의류',
  '전자제품',
  '가구',
  '아동상품',
  '뷰티_미용',
  '취미생활',
  '식품',
  '기타',
];
